<template>
  <div>
    <b-card>
      <filter-swapper :buttons="[...buttons]" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :key="keyFormRender" :form.sync="formFilter" :fields="fields" @send="simpleFilter" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-3 container-button mt-2" class="mb-2">
            <template v-slot:buttons>
              <b-dropdown class="ml-2" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
                <template #button-content><feather-icon icon="SettingsIcon"/></template>
                <b-dropdown-item @click="cleanForm">{{$t('Limpiar filtros')}}</b-dropdown-item>
                <b-dropdown-item v-b-modal.ModalReturnsFilter>{{$t('Búsqueda avanzada')}}</b-dropdown-item>
              </b-dropdown>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="!loading.first">
        <table-render id="table-deliveries-return" :schema="schema" :rows="rows" :loading="loading.deliveriesReturn">
          <template #current_status="scope">
            <span :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.status_data.id]}`"></span> {{scope.rowdata.status.name}}
          </template>
          <template #num_return="scope">
            <b-button class="p-0" variant="link" @click="goTo(scope.rowdata.id, URLS.returns)">{{scope.rowdata.original_id || scope.rowdata.id}}</b-button>
          </template>
          <template #num_imported="scope">
            <b-button v-if="scope.rowdata.parent_delivery_id && scope.rowdata.order.imported_id" class="p-0" variant="link" @click="goTo(scope.rowdata.parent_delivery_id, URLS.deliveries)">{{scope.rowdata.order.imported_id}}</b-button>
            <span v-else-if="!scope.rowdata.parent_delivery_id && scope.rowdata.order.imported_id">{{scope.rowdata.order.imported_id}}</span>
            <span v-else>-</span>
          </template>
          <template #tracking_number="scope">
            {{scope.rowdata.carrier.tracking_number || 'Sin info'}}
          </template>
          <template #created_at="scope">
            {{scope.rowdata.created_at.split('.')[0]}}
          </template>
          <template #deadline_at="scope">
            {{(scope.rowdata.deadline_at || '-').split('.')[0]}}
          </template>
        </table-render>
        <pagination :pagination="pagination" :noDigits="true" :onlyText="true" />
      </div>
      <b-skeleton type="input" v-if="loading.first" class="mb-2 mt-2 spacing-label-field"/>
      <div class="table-render-skeleton" v-if="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
    <modal-returns-filter @filter="filterList" ref="ModalReturnsFilter" :statusDelivery="deliveryStatusesFull" :date="formFilter.date_range"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { environment } from '@/environments/environment'
import ModalReturnsFilter from '../ModalReturnsFilter'
const moment = require('moment-timezone')

export default {
  name: 'list-returns',
  components: {ModalReturnsFilter},
  data () {
    return {
      buttons: [],
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      fields: [
        { fieldType: 'FieldDatepicker', name: 'date_range', label: 'Fecha', range: true, containerClass: 'col-sm-12 col-md-4' },
        { fieldType: 'FieldInput', label: '# de envío', placeholder: 'Buscar envío', name: 'references', containerClass: 'col-sm-12 col-md-4' }
      ],
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      schema: [],
      rows: [],
      deliveryStatus: [],
      initialDateRange: { start: this.$options.filters.momentSubtract(30), end: this.$options.filters.momentAdd(7) },
      form: {},
      formFilter: {},
      callWithPagination: false,
      pagination: {
        page: 1,
        total: 10,
        limit: 10
      },
      timezones: {
        'CL':	'America/Santiago',
        'MX':	'America/Chihuahua',
        'CO':	'America/Bogota',
        'PE':	'America/Lima',
        'AR':	'America/Buenos_Aires',
        'EC':	'America/Guayaquil',
        'US':	'America/New_York'
      },
      URLS: Object.freeze({
        deliveries: 'deliveries',
        // returns: 'return-deliveries'
        returns: 'returns'
      }),
      userData: {},
      deliveryStatuses: [],
      keyFormRender: 0,
      loading: {
        initial: true,
        first: true,
        deliveriesReturn: false
      },
      firstCall: true
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      deliveryStatusesFull: 'getDeliveryStatusesFull',
      deliveriesReturn: 'getDeliveriesReturn'
    })
  },
  mounted () {
    this.initialLoad()
    this.firstCall = false
  },
  watch: {
    mySession() {
      if (this.mySession.id && this.firstCall) this.initialLoad()
    },
    deliveryStatusesFull () {
      this.deliveryStatuses = this.deliveryStatusesFull
    },
    deliveriesReturn () {
      const countryFilter = this.formFilter['country_code'] || this.userData?.country.code || 'CL'
      const {rows, total} = this.deliveriesReturn
      this.rows = rows.map(delivery => {
        const countryTimezone = this.timezones[delivery.origin.address.country_code]
        if (delivery.status && !delivery.status.id && delivery.status.name) {
          delivery.status_data = this.deliveryStatuses.find(el => el.name === delivery.status.name) || {id: 1, ...delivery.status} 
        } else {
          delivery.status_data = delivery.status
        }

        const lowestLevels = {
          'CL': ['level3', 'level2', 'level1'],
          'MX': ['level4', 'level3', 'level2'],
          'CO': ['level2', 'level1', 'level0'],
          'PE': ['level3', 'level2', 'level1'],
          'AR': ['level4', 'level3', 'level2'],
          'EC': ['level4', 'level3', 'level2']
        }
        const lowestLevel = lowestLevels[countryFilter][0]
        const parentLowestLevel = lowestLevels[countryFilter][1]
        const grandParentLowestLevel = lowestLevels[countryFilter][2]

        delivery.city = delivery.origin.address[lowestLevel]
        delivery.province = delivery.origin.address[parentLowestLevel]
        delivery.state = delivery.origin.address[grandParentLowestLevel]
        delivery.created_at = this.$options.filters.moment(moment(delivery.created_at).tz(countryTimezone), 'YYYY-MM-DD HH:mm:ss')
        return delivery        
      })
      this.loading.first = false
      this.loading.deliveriesReturn = false
      if (total >= 50) this.callWithPagination = true
    },
    'pagination.page' () {
      this.getReturnsDelivery()
    }
  },
  methods: {
    initialLoad () {
      this.setInitialData()
      this.getReturnsDelivery()
    },
    setInitialData () {
      this.formFilter.date_range = this.initialDateRange
      this.userData = this.$session.get('cas_user')
      this.formFilter.country_code = this.formFilter['country_code'] || this.userData?.country.code || 'CL'
      this.$store.dispatch('fetchService', {name: 'getDeliveryStatusesFull'})
      const countryFilter = this.formFilter['country_code'] || this.userData?.country.code || 'CL'

      this.schema = [
        {label: 'Estado', key: 'current_status', useSlot: true},
        {label: 'N° de devolucion', key: 'num_return', useSlot: true},
        {label: 'N° de envío', key: 'num_imported', useSlot: true},
        {label: 'Orden de flete', key: 'tracking_number', useSlot: true},        
        {label: 'Cliente', key: 'origin.contact.name'},        
        {label: 'Courier', key: 'carrier.name'},
        {label: 'Fecha creación', key: 'created_at', useSlot: true},
        {label: 'Fecha compromiso', key: 'deadline_at', useSlot: true}
      ]

      //@if(Auth::user()->role_id == 1 || Auth::user()->role_id == 4)
      if (['marketplace', 'superadmin', 'admin'].includes(this.userData?.role)) {
        this.schema.unshift({label: 'Empresa', key: 'shipper.name'})
      }

      if (['superadmin', 'admin'].includes(this.userData?.role)) {
        this.schema.unshift({label: 'Organización', key: 'organization.name'})
      }

      // Condiciones para agregar otras columnas
      //if (this.userData?.country.id !== 2 || ['superadmin', 'admin'].includes(this.userData?.role)) {
      const { Place_id, Place_level1, Place_level2, Place_level3 } = this.$i18nImport(`${countryFilter.toLowerCase()}.es`)[`${countryFilter.toLowerCase()}.es`]
      this.schema.splice(this.getElementIndex(this.schema, 'Cliente') + 1, 0, {label: Place_id, key: 'city'})

      if (this.userData?.country.id === 2) {
        this.schema.splice(this.getElementIndex(this.schema, Place_id), 0, {label: 'Código postal', key: 'origin.address.zip_code'})
      }

      if (this.userData?.country.id === 4) {
        this.schema.splice(this.getElementIndex(this.schema, Place_id), 0, {label: Place_level2, key: 'province'})
      }
    },
    /**
     * Guardar los datos de filtro avanzado para que se pueda llamar "con paginacion" en caso de que se requiera
     * @param {object} form Recibe el formulario del filtro con los datos a buscar
     */
    filterList (data, isExportation = false) {
      this.callWithPagination = false
      this.formFilter = {}

      Object.keys(data).map(key => { 
        if (data[key] !== null && data[key] !== '') {
          if (key !== 'date_range') {
            if (key === 'shipper') this.formFilter['company_id'] = data[key].id
            else if (key === 'country') this.formFilter['country_code'] = data[key].code
            else if (key === 'service') this.formFilter['carrier_service'] = data[key].code
            else if (key === 'carrier') this.formFilter['carrier_code'] = data[key].carrier_code
            else if (key === 'place') this.formFilter['origin_place_platform_id'] = data[key].id
            else if (key === 'flow_action') this.formFilter['status_ids'] = data[key].map(action => action.id).join('-')
            else this.formFilter[key] = data[key]
          } else {
            this.formFilter.date_range = data[key]
          }
        }
      })
      if (!isExportation) { 
        this.pagination.page !== 1 ? this.pagination.page = 1 : this.getReturnsDelivery()
      } else {
        //Se llama desde aqui para poder tomar el dato del filtro simple tambien
        this.$refs.ModalReturnsFilter.exportData(this.formFilter)
      }
    },
    simpleFilter() {
      this.pagination.page > 1 ? this.pagination.page = 1 : this.getReturnsDelivery()
    },
    getReturnsDelivery() {
      const filteredForm = {}
      this.loading.deliveriesReturn = true
      if (this.formFilter.date_range) {
        this.formFilter.created_at_from = this.$options.filters.moment(this.formFilter.date_range.start, 'YYYY-MM-DD HH:mm:ss')
        this.formFilter.created_at_to = this.$options.filters.moment(this.formFilter.date_range.end, 'YYYY-MM-DD HH:mm:ss')
      }
      Object.keys(this.formFilter).forEach(k => {
        if (this.formFilter[k] !== null && this.formFilter[k] !== '') {
          filteredForm[k] = this.formFilter[k]
        }
      })
      if (this.formFilter.references) {
        filteredForm.references = this.formFilter.references.split(',').map(reference => reference.trim()).toString()
      }
      const queryParams = {
        ...filteredForm,
        delivery_type: 'return',
        extended: 1,
        limit: 50
        // country_code: this.formFilter['country_code']
      }
      const headers = {
        'Content-Type': 'application/json'
      }
      delete queryParams.date_range
      if (this.callWithPagination) queryParams.page = this.pagination.page
      this.$store.dispatch('fetchService', {name: 'getDeliveriesReturn', queryParams, headers, onError: () => this.loading.deliveriesReturn = false})
    },
    goTo (id, name) {
      window.open(`${environment.platformUrl}/${name}/${id}`, '_blank')
    },
    getElementIndex(array, value) {
      return array.findIndex(fieldsData => fieldsData.label === value)
    },
    cleanForm () {
      this.callWithPagination = false
      this.formFilter = {}
      this.formFilter.date_range = this.initialDateRange
      this.pagination.page > 1 ? this.pagination.page = 1 : this.getReturnsDelivery()
      this.$refs.ModalReturnsFilter.cleanForm() //limpiar el modal del filtro
    }
  }
}
</script>

<style>
</style>
